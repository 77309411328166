import React from 'react'

export default {
  title: 'The easiest way to hedge your crypto',
  description:
    'Ready to protect your portfolio from volatility? Thinking of ways to earn more with options? Pods is here for you.',
  sell: {
    title: (
      <>
        Sell options and
        <br />
        earn more
      </>
    ),
    description:
      'Generate additional yield on your aTokens and get exposure to assets of your choice.'
  },
  buy: {
    title: (
      <>
        Buy options to protect
        <br />
        your portfolio
      </>
    ),
    description:
      'Investing in crypto does not have to mean suffering from volatility fever. Reduce your exposure to price downside by placing hedges in your portfolio.'
  }
}
