import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import globals from '@pods-finance/globals'
import { Button } from '@pods-finance/components'

import { useIntersection } from '../../../hooks'

import { Logo } from './atoms'
import { Canvas } from '../../atoms'
import { pages } from '../../../constants'

const Wrapper = styled.div`
  width: 100%;
`

const BarPartial = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${props => props.theme.sizes.navHeight};
  width: 100%;
  background: ${props => props.theme.colors.dark};
  z-index: ${props => props.theme.sizes.navElevation};
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  z-index: 100;
  ${props => props.theme.medias.small} {
    padding-right: 10px;
  }
`

const Underlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const UnderlayHalf = styled.div`
  flex: 1;
  height: 100%;
  &:nth-child(2) {
    background: ${props => props.theme.gradients.primaryBuilder(90)};
  }
`

const Middle = styled.div`
  height: 100%;
  flex: 1;
  ${props => props.theme.medias.small} {
    flex: none;
    width: ${props => props.theme.sizes.edge};
  }
`

const Identity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -${props => props.theme.sizes.edge};
  padding: 0 25px;
  background: ${props => props.theme.gradients.primary};
  height: 100%;
  ${props => props.theme.medias.micro} {
    padding: 0 15px;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > * {
    margin-right: calc(${props => props.theme.sizes.edge} * 1);
    &:last-child {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  ${props => props.theme.medias.small} {
    justify-content: flex-end;
    & > * {
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`

const Page = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border-radius: 40px;
  background-color: transparent;
  transition: background-color 100ms;
  opacity: 0.7;

  & > div {
    border: 2px solid ${props => props.theme.colors.white};
    height: 14px;
    width: 22px;
    border-radius: 7px;
    flex-shrink: 0;
    margin-right: 8px;
  }
  & > p {
    margin: 0;
    font-size: 11pt;
    color: ${props => props.theme.colors.white};
    font-weight: 700;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    transform-origin: center center;
    transform: scaleX(0);
    transition: transform 500ms;
  }
  &:hover,
  &:active {
    &:after {
      transform: scaleX(0.8);
      transition: transform 200ms;
    }
  }

  &.active {
    opacity: 1;
    & > div {
      width: 14px;
    }
  }

  ${props => props.theme.medias.small} {
    justify-content: center;
    height: 52px;
    width: auto;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 12px;
    &:after {
      display: none;
    }
    &.active {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
`

const Intersection = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  order: 0;
`

const Bar = styled(BarPartial)`
  ${Underlay} {
    opacity: 1;
    transition: opacity 250ms;
  }

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  transition: box-shadow 250ms;

  &[data-top='false'] {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
    transition: box-shadow 250ms;
    ${Underlay} {
      opacity: 0;
      transition: opacity 250ms;
    }
  }
`

const UseButton = styled(Button)`
  p {
    font-size: 11pt !important;
    font-weight: 600 !important;
  }
  ${props => props.theme.medias.small} {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 2px 30px 2px 2px;
  }

  ${props => props.theme.medias.micro} {
    display: none;
  }
`

function Nav ({ isStatic }) {
  const [ref, entry] = useIntersection({
    threshold: 0,
    rootMargin: '0px',
    root: null
  })

  return (
    <Wrapper>
      <Bar data-top={!isStatic && entry.intersectionRatio === 1}>
        <Content>
          <Identity>
            <Logo />
          </Identity>
          <Middle />
          <Menu>
            <Page exact to={pages.landing.route}>
              <div />
              <p>Home</p>
            </Page>

            <Page exact to={pages.seller.route}>
              <div />
              <p>Earn</p>
            </Page>

            <UseButton
              type={t => t.external}
              appearance={a => a.gradient}
              accent={a => a.whitePrimary}
              title='Use the DApp'
              titleShort='DApp'
              to={globals.host.platform.auto()}
            />
          </Menu>
        </Content>
        <Underlay>
          <UnderlayHalf />
          <UnderlayHalf />
        </Underlay>
      </Bar>
      <Intersection ref={ref} />
    </Wrapper>
  )
}

Nav.propTypes = {
  isStatic: PropTypes.bool
}

Nav.defaultProps = {
  isStatic: false
}

export default Nav
