import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@pods-finance/components'
import globals from '@pods-finance/globals'
import { Canvas } from '../../../atoms'
import {
  Title as TitlePartial,
  Description as DescriptionPartial
} from '../../../shared/Section'

import Underlay from './Underlay'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 300px 0 140px 0;
  background: ${props => props.theme.colors.dark};
  ${props => props.theme.medias.small} {
    padding-top: 0;
  }
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  padding-top: 20px;
  ${props => props.theme.medias.small} {
    flex-direction: column;
  }
`

const InvestButton = styled(Button)``

const Title = styled(TitlePartial)`
  & > p {
    & > span,
    & > b {
      &:after {
        background: ${props => props.theme.colors.white};
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  margin: calc(${props => props.theme.sizes.edge} * 5) auto;
  max-width: 900px;
  ${props => props.theme.medias.small} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: span 1;
  border-radius: 4px;
  padding: calc(${props => props.theme.sizes.edge} * 2);
  background-color: rgba(255, 255, 255, 0.05);
  transition: background-color 150ms;
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
    transition: background-color 250ms;
  }

  & > p {
    font-size: 12pt;
    font-weight: 400;
    color: ${props => props.theme.colors.white};
    line-height: 1.5;
    margin: 0;
    text-align: center;
    & > b {
      font-weight: 600;
    }
  }
`

const Description = styled(DescriptionPartial)`
  a {
    text-decoration: underline !important;
  }
`

function Liquidity ({ data }) {
  return (
    <Wrapper id='liquidity'>
      <Content>
        <Title isLight align='center' alignSmall='center'>
          {_.get(data, 'title')}
        </Title>
        <Description isLight align='center' alignSmall='center'>
          {_.get(data, 'description')}
        </Description>

        <Grid>
          {_.toArray(_.get(data, 'list')).map(item => (
            <Item key={item}>
              <p>{item}</p>
            </Item>
          ))}
        </Grid>

        <InvestButton
          type={t => t.external}
          appearance={a => a.solid}
          accent={a => a.whiteDark}
          to={globals.host.platform.auto()}
          title={_.get(data, 'action')}
        />
      </Content>
      <Underlay />
    </Wrapper>
  )
}

Liquidity.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Liquidity
