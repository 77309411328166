import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconArrow from '@material-ui/icons/ArrowForwardRounded'
import { ExternalLink } from '@pods-finance/components'
import { darken } from 'polished'
import { Canvas } from '../../../atoms'
import { Title } from '../../../shared/Section'

// import Dot from '../../../../assets/decorator/dot.svg'
import ImagePlatform from '../../../../assets/images/platform_buy.png'
import ImageCode from '../../../../assets/images/code.png'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 2) 0 0 0;
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr 160px;
  grid-gap: calc(${props => props.theme.sizes.edge} * 2);
  margin-top: calc(${props => props.theme.sizes.edge} * 2);
  height: 460px;
  ${props => props.theme.medias.medium} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }
`

const Cell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 2);
  &:last-child {
    justify-content: flex-end;
  }
  ${props => props.theme.medias.medium} {
    align-items: center;
    &:first-child {
      padding-bottom: 0;
      margin: 0 auto;
    }
    &:last-child {
      padding-top: calc(${props => props.theme.sizes.edge} * 2);
      margin: 0 auto;
    }
  }
`

const CellTitle = styled.p`
  font-size: 13pt;
  font-weight: 800;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 calc(${props => props.theme.sizes.edge} * 1.5) 0;
  line-height: 1.5;
`

const CellLink = styled(ExternalLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  & > p {
    font-size: 11pt;
    font-weight: 500;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
  & > svg {
    font-size: 12pt;
    margin-left: calc(${props => props.theme.sizes.edge} * 1);
    color: ${props => props.theme.colors.dark};
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: -6px;
    content: '';
    height: 2px;
    width: 100%;
    background-color: ${props => props.theme.colors.dark};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 150ms;
  }

  &:hover,
  &:active {
    &:after {
      transform: scaleX(1);
      transition: transform 150ms;
    }
  }

  ${props => props.theme.medias.medium} {
    min-width: 200px;
    border-radius: 2px;
    background-color: ${props => props.theme.colors.grayBlueLight};
    justify-content: center;
    padding: 10px;
    & > p {
      text-align: left;
      flex: 1;
    }
  }
`

const Underlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-repeat: space;
  background-size: 20px;
`

const Middle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  ${props => props.theme.medias.medium} {
    height: 500px;
    width: calc(100% + 60px);
    margin-right: -60px;
  }
  ${props => props.theme.medias.small} {
    height: 380px;
  }
  ${props => props.theme.medias.micro} {
    height: 300px;
  }
`

const Laptop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const LaptopBezel = styled.div`
  width: 100%;
  flex: 1;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: ${props => props.theme.colors.dark};
  border: 6px solid ${props => props.theme.colors.dark};
  overflow: hidden;
  ${props => props.theme.medias.medium} {
    border-top-left-radius: 6px;
    border-top-right-radius: 0px;
  }
`

const LaptopScreen = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const Left = styled.div`
  height: 100%;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;
  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left top;
    transform: scale(1.2);
    transform-origin: left top;
  }
`

const Right = styled.div`
  height: 100%;
  flex: 1;
  background-color: ${props => darken(0.05, props.theme.colors.dark)};
  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left top;
  }
`

const LaptopChin = styled.div`
  height: 34px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colors.dark};

  & > p {
    font-size: 9pt;
    font-weight: 800;
    color: ${props => props.theme.colors.white};
    text-align: center;
    margin: 0;
  }

  ${props => props.theme.medias.medium} {
    border-bottom-left-radius: 6px;
  }
`

function Built ({ data }) {
  return (
    <Wrapper>
      <Content>
        <Title align='center'>{_.get(data, 'title')}</Title>
        <Container>
          <Cell>
            <CellTitle>{_.get(data, 'cell.left.title')}</CellTitle>
            {_.get(data, 'cell.left.list').map(item => (
              <CellLink to={_.get(item, 'to')} key={_.get(item, 'title')}>
                <p>{_.get(item, 'title')}</p> <IconArrow />
              </CellLink>
            ))}
          </Cell>
          <Middle>
            <Laptop>
              <LaptopBezel>
                <LaptopScreen>
                  <Left>
                    <img src={ImagePlatform} alt='Platform' />
                  </Left>
                  <Right>
                    <img src={ImageCode} alt='Smart Contract' />
                  </Right>
                </LaptopScreen>
              </LaptopBezel>
              <LaptopChin>
                <p>EthBook</p>
              </LaptopChin>
            </Laptop>
          </Middle>
          <Cell>
            <CellTitle>{_.get(data, 'cell.right.title')}</CellTitle>

            {_.get(data, 'cell.right.list').map(item => (
              <CellLink to={_.get(item, 'to')} key={_.get(item, 'title')}>
                <p>{_.get(item, 'title')}</p> <IconArrow />
              </CellLink>
            ))}
          </Cell>
        </Container>
      </Content>
      <Underlay />
    </Wrapper>
  )
}

Built.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Built
