import React from 'react'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'

export default {
  title: <>Become a Liquidity Provider</>,
  description: (
    <>
      Earn additional fees while providing liquidity to the options AMM.
      <br />
      The Pods Options AMM allows for <b>dual-sided</b> liquidity provision.
      This means a user can provide options tokens and stablecoin. Bear in mind
      that providing liquidity in an options AMM is different from a
      general-purpose AMM (e.g., Uniswap). <br />
      Learn more{' '}
      <ExternalLink to={globals.links.gitbook.AMM.link}>here</ExternalLink>.
    </>
  ),
  list: [
    'Add liquidity with stablecoins (or interest bearing stablecoins) and earn transaction fees.',
    'Add liquidity with option tokens and earn transaction fees.',
    'Add both options tokens and stablecoins to the AMM and earn fees on both ends.'
  ],
  action: 'Provide Liquidity'
}
