import React from 'react'
import styled from 'styled-components'
import { Canvas, Layer as LayerPartial } from '../../../../atoms'
import { ReactComponent as DecoratorOvalLarge } from '../../../../../assets/decorator/oval_large.svg'

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
  height: 100%;
`

const Card = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  &:after {
    content: '';
    display: block;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-left: -100px;
    margin-top: -180px;
    opacity: 0.7;
    background: ${(props) => props.theme.colors.grayBlueGhost};
  }
  &[data-dark='true'] {
    background-color: ${(props) => props.theme.colors.dark};
    &:after {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 260px;
  left: 0;
  z-index: 50;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;

  & > ${Content} {
    position: relative;
    & > ${Card} {
      height: 260px;
      width: 600px;
      position: absolute;
      &:first-child {
        top: calc(${(props) => props.theme.sizes.edge} * 2);
        left: calc(-1 * (600px + ${(props) => props.theme.sizes.edge}));
      }
      &:last-child {
        top: calc(${(props) => props.theme.sizes.edge} * 2);
        right: calc(-1 * (600px + ${(props) => props.theme.sizes.edge}));
      }
    }
  }
`

const Layer = styled(LayerPartial)`
  top: 260px;

  & > * {
    &:first-child {
      height: 300px;
      width: 300px;
      position: absolute;
      top: -360px;
      left: -240px;
    }
    &:last-child {
      height: 300px;
      width: 300px;
      position: absolute;
      top: -60px;
      right: -260px;
    }
  }

  ${(props) => props.theme.medias.small} {
    display: none;
  }
`

function Underlay () {
  return (
    <Wrapper>
      <Layer elevation={15}>
        <DecoratorOvalLarge />
        <DecoratorOvalLarge />
      </Layer>
      <Content>
        <Card />
        <Card data-dark />
      </Content>
    </Wrapper>
  )
}

export default Underlay
