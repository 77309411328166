import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Theme from '@pods-finance/themes'

import { pages } from '../constants'

import Landing from './Landing'
import Seller from './Seller'

function Wrapper ({ children }) {
  return (
    <Theme.Provider>
      <Theme.GlobalStyle />
      <BrowserRouter>{children}</BrowserRouter>
    </Theme.Provider>
  )
}

function Routes () {
  return (
    <Switch>
      <Route path={pages.seller.route} component={Seller} exact />
      <Route path={pages.landing.route} component={Landing} exact />
      <Redirect to={pages.landing.route} />
    </Switch>
  )
}

function App () {
  return (
    <Wrapper>
      <Routes />
    </Wrapper>
  )
}

export default App
