import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import ReactGA from 'react-ga'
import globals from '@pods-finance/globals'

if (globals.ENVIRONMENT.isProduction()) {
  ReactGA.initialize('165955753')
} else {
  ReactGA.initialize('test', { testMode: true })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
