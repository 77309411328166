import * as data from './data'
import { meta } from '@pods-finance/globals'

export { data, meta }

export const pages = {
  landing: {
    route: '/',
    builder: () => '/',
    title: 'Landing'
  },
  seller: {
    route: '/seller',
    builder: () => '/seller',
    title: 'About option sellers'
  },
  contact: {
    route: '/contact',
    builder: () => '/contact',
    title: 'Contact'
  }
}
