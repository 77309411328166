import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import globals from '@pods-finance/globals'
import { ExternalLink, Newsletter } from '@pods-finance/components'
import { Canvas } from '../../atoms'
import LogoAsset from '../../../assets/logo/pods_white.svg'
import { ReactComponent as LogoDefiPulse } from '../../../assets/images/defipulse.svg'
import { pages } from '../../../constants'

const Wrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.night};
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: calc(${props => props.theme.sizes.edge} * 4);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 4);
`

const LogoWrapper = styled(Link)`
  height: 30px;
  cursor: pointer;
  & > img {
    object-fit: contain;
    object-position: left;
    height: 100%;
    min-width: 62px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: calc(${props => props.theme.sizes.edge} * 4);
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  width: 100%;
  & > * {
    grid-column: span 2;
    &[data-purpose='newsletter'] {
      grid-column: span 3;
    }
  }
  ${props => props.theme.medias.large} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: calc(${props => props.theme.sizes.edge} * 2);
    & > * {
      grid-column: span 1;
      &[data-purpose='newsletter'] {
        grid-column: span 1;
      }
    }
  }
  ${props => props.theme.medias.micro} {
    grid-gap: calc(${props => props.theme.sizes.edge} * 1);
    grid-template-columns: repeat(1, 1fr);
  }
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const CellGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: calc(${props => props.theme.sizes.edge} * 0);
  grid-gap: calc(${props => props.theme.sizes.edge} * 2);
  grid-auto-flow: column;
  width: 100%;
  & > * {
    grid-column: span 1;
  }
`

const Title = styled.p`
  font-size: 12pt;
  font-weight: 800;
  color: ${props => props.theme.colors.white};
  margin: 0 0 calc(${props => props.theme.sizes.edge} * 2) 0;
  line-height: 1.5;
`
const Target = styled(ExternalLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  & > p {
    font-size: 10pt;
    font-weight: 400;
    color: ${props => props.theme.colors.white};
    margin: 0;
  }

  &:after {
    position: absolute;
    bottom: -6px;
    left: 0;
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 150ms;
  }

  &:hover,
  &:active {
    &:after {
      transform: scaleX(1);
      transition: transform 150ms;
    }
  }
`

const EcosystemLogo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  & > svg {
    height: 20px;
    width: auto;
  }
`

const NewsletterWrapper = styled.div`
  width: 100%;
`

function Footer () {
  return (
    <Wrapper>
      <Content>
        <LogoWrapper to='/'>
          <img src={LogoAsset} alt='Pods.Finance' />
        </LogoWrapper>
        <Grid>
          <Cell>
            <Title>Quick Actions</Title>
            <CellGrid>
              <Cell>
                <Target to={globals.host.platform.auto()}>
                  <p>App</p>
                </Target>
                <Target
                  to={globals.host.landing.auto(pages.seller.route)}
                  target='_self'
                >
                  <p>Earn</p>
                </Target>
                <Target to={globals.host.platform.auto('/pool')}>
                  <p>Provide liquidity</p>
                </Target>
              </Cell>
            </CellGrid>
          </Cell>

          <Cell>
            <Title>Protocol</Title>
            <CellGrid>
              <Cell>
                <Target to={globals.links.whitepaper.link}>
                  <p>White Paper</p>
                </Target>
                <Target to={globals.links.gitbook.primary.link}>
                  <p>Documentation</p>
                </Target>
                <Target to={globals.links.gitbook.etherscan.link}>
                  <p>Etherscan Links</p>
                </Target>
              </Cell>
            </CellGrid>
          </Cell>
          <Cell data-purpose='newsletter'>
            <Title>Newsletter</Title>
            <NewsletterWrapper>
              <Newsletter />
            </NewsletterWrapper>
          </Cell>
          <Cell>
            <Title>Company</Title>
            <CellGrid>
              <Cell>
                <Target to={globals.links.notion.team.link}>
                  <p>About Us</p>
                </Target>
                <Target to={globals.links.notion.hire.link}>
                  <p>Jobs</p>
                </Target>
              </Cell>
            </CellGrid>
          </Cell>

          <Cell>
            <Title>Social Links</Title>
            <CellGrid>
              <Cell>
                <Target to={globals.links.twitter.primary.link}>
                  <p>Twitter</p>
                </Target>
                <Target to={globals.links.discord.primary.link}>
                  <p>Discord</p>
                </Target>
                <Target to={globals.links.medium.primary.link}>
                  <p>Medium</p>
                </Target>
                <Target to={globals.links.github.primary.link}>
                  <p>Github</p>
                </Target>
                <Target to={globals.links.youtube.primary.link}>
                  <p>Youtube</p>
                </Target>
              </Cell>
            </CellGrid>
          </Cell>
          <Cell>
            <Title>Ecosystem</Title>
            <CellGrid>
              <Cell>
                <Target to={globals.links.defipulse.primary.link}>
                  <EcosystemLogo>
                    <LogoDefiPulse />
                  </EcosystemLogo>
                </Target>
              </Cell>
            </CellGrid>
          </Cell>
        </Grid>
      </Content>
    </Wrapper>
  )
}

export default Footer
