import AssetInvestorFramework from '../../assets/images/investors/investor_framework.png'
import AssetInvestorRoble from '../../assets/images/investors/investor_roble.png'
import AssetInvestorBoost from '../../assets/images/investors/investor_boost.png'
import AssetInvestorZeePrime from '../../assets/images/investors/investor_zeeprime.png'
import AssetInvestorP2P from '../../assets/images/investors/investor_p2p.png'
import AssetInvestorLAO from '../../assets/images/investors/investor_lao.png'
import AssetInvestorBitscale from '../../assets/images/investors/investor_bitscale.png'

export default {
  list: [
    {
      name: 'Framework',
      image: AssetInvestorFramework,
      website: 'https://framework.ventures/'
    },
    {
      name: 'BoostVC',
      image: AssetInvestorBoost,
      website: 'https://www.boost.vc/'
    },
    {
      name: 'Roble',
      image: AssetInvestorRoble,
      website: 'https://digitalassetsinstitute.com/roble-venture-capital/'
    },
    {
      name: 'Bitscale',
      image: AssetInvestorBitscale,
      website: 'https://bitscale.capital/'
    },
    {
      name: 'P2P Capital',
      image: AssetInvestorP2P,
      website: 'https://www.p2pcap.com/'
    },
    {
      name: 'The LAO',
      image: AssetInvestorLAO,
      website: 'https://www.thelao.io/'
    },

    {
      name: 'Zee Prime',
      image: AssetInvestorZeePrime,
      website: 'https://zeeprime.capital/'
    }
  ]
}
