import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Dots } from '../../../../../assets/decorator/dots.svg'
import { ReactComponent as Waves } from '../../../../../assets/decorator/waves-2.svg'
import { Layer as LayerPartial } from '../../../../atoms'

const Layer = styled(LayerPartial)`
  &[data-name='decorator'] {
    & > svg {
      height: 300px;
      width: 300px;
      position: absolute;
      top: -320px;
      right: -100px;
    }
  }
`

const DotsRight = styled.div`
  height: 490px;
  width: 490px;
  position: absolute;
  top: 150px;
  right: -440px;
  opacity: 1;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.white};
  }
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const WavesWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  & > svg {
    width: 100%;
    height: 100%;
  }
`

function Underlay () {
  return (
    <>
      <Layer elevation={3}>
        <DotsRight>
          <Dots />
        </DotsRight>
      </Layer>
      <Layer elevation={2}>
        <WavesWrapper>
          <Waves />
        </WavesWrapper>
      </Layer>
    </>
  )
}

export default Underlay
