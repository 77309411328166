import React from 'react'
import styled from 'styled-components'
import { Canvas, Layer } from '../../../../atoms'
import { ReactComponent as Dots } from '../../../../../assets/decorator/dots.svg'
import { ReactComponent as DecoratorGroup } from '../../../../../assets/decorator/oval_group_small.svg'

const Content = styled(Canvas)`
  display: flex;
  z-index: 100;
  height: 100%;
  position: relative;
  ${props => props.theme.medias.small} {
    flex-direction: column;
  }
`

const DecoratorWrapper = styled.div`
  height: 180px;
  width: 180px;
  position: relative;

  & > svg {
    height: 100%;
    width: 100%;
  }

  &[data-location='1'] {
    position: absolute;
    right: -160px;
    top: 80px;
    transform: rotate(0deg);
  }
  &[data-location='2'] {
    position: absolute;
    left: -150px;
    bottom: 40px;
    transform: rotate(0deg);
  }
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const DotsRight = styled.div`
  height: 490px;
  width: 490px;
  right: -130px;
  bottom: -400px;
  position: absolute;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.dark};
  }
`

function Underlay () {
  return (
    <>
      <Layer elevation={3}>
        <Content>
          <DecoratorWrapper data-location={1}>
            <DecoratorGroup />
          </DecoratorWrapper>
        </Content>
      </Layer>
      <Layer elevation={2}>
        <Content>
          <DecoratorWrapper data-location={2}>
            <DecoratorGroup />
          </DecoratorWrapper>
        </Content>
      </Layer>
      <Layer elevation={1}>
        <DotsRight>
          <Dots />
        </DotsRight>
      </Layer>
    </>
  )
}

export default Underlay
