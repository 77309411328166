import React from 'react'
import styled from 'styled-components'
import { ReactComponent as DecoratorOvalGroup } from '../../../../../assets/decorator/oval_group_small.svg'
import { Layer as LayerPartial } from '../../../../atoms'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.colors.dark};
  height: auto;
  width: 100%;
  z-index: 1;
`

const Layer = styled(LayerPartial)`
  & > * {
    position: absolute;
    top: 0;
    left: 100px;
  }

  ${props => props.theme.medias.small} {
    & > * {
      left: -140px;
      top: -180px;
      height: 300px;
      width: 300px;
      transform: rotate(20deg);
    }
  }
`

function Underlay () {
  return (
    <Wrapper>
      <Layer elevation={15}>
        <DecoratorOvalGroup />
      </Layer>
    </Wrapper>
  )
}

export default Underlay
