import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import globals from '@pods-finance/globals'
import { Button } from '@pods-finance/components'
import { Canvas } from '../../../atoms'
import { Title, Description } from '../../../shared/Section'

import Underlay from './Underlay'
import Illustration from './Illustration'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 200px 0 50px 0;
  background-color: ${props => props.theme.colors.dark};
  ${props => props.theme.medias.small} {
    padding-top: 100px;
    margin-top: (${props => props.theme.sizes.edge} * 1);
  }
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 100;
  ${props => props.theme.medias.small} {
    flex-direction: column;
    ol {
      padding-left: 10px !important;
    }
  }
`

const Left = styled.div`
  flex: 1;
  ${props => props.theme.medias.small} {
    flex: none;
    width: 100%;
  }
`

const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin-top: -40px;
  ${props => props.theme.medias.small} {
    margin-top: 0px;
    max-width: 100%;
    align-items: center;
    text-align: center !important;
    justify-content: center !important;
  }
`

const Right = styled.div`
  flex: 1;
  padding-top: 100px;
  ${props => props.theme.medias.small} {
    flex: none;
    width: 100%;
    padding-top: calc(${props => props.theme.sizes.edge} * 2);
  }
`

const InvestButton = styled(Button)`
  margin-top: calc(${props => props.theme.sizes.edge} * 3);
`

function Collateral ({ data }) {
  return (
    <Wrapper id='smart'>
      <Content>
        <Left>
          <LeftContent>
            <Title isLight alignSmall='center'>
              {_.get(data, 'title')}
            </Title>
            <Description isLight alignSmall='center'>
              {_.get(data, 'description')}
            </Description>
            <InvestButton
              type={t => t.external}
              appearance={a => a.gradient}
              accent={a => a.whitePrimary}
              to={globals.host.platform.auto()}
              title={_.get(data, 'action')}
            />
          </LeftContent>
        </Left>
        <Right>
          <Illustration />
        </Right>
      </Content>
      <Underlay />
    </Wrapper>
  )
}

Collateral.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Collateral
