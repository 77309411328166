import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ExternalLink } from '@pods-finance/components'
import { HashLink } from 'react-router-hash-link'
import IconArrowForward from '@material-ui/icons/ArrowForwardRounded'
import IconArrowSub from '@material-ui/icons/SubdirectoryArrowLeftRounded'

const Wrapper = styled.div`
  grid-column: span 1;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: ${props => props.theme.colors.white};
  position: relative;
  ${props => props.theme.medias.small} {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
    margin: 0 auto;
    max-width: 400px;
  }
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  position: relative;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Asset = styled.img`
  width: 140px;
  object-fit: contain;
  object-position: bottom;
  position: absolute;
  top: -60px;
`

const Title = styled.div`
  font-size: 18pt;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Description = styled.div`
  font-size: 12pt;
  font-weight: 300;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  margin: 0;
  max-width: 600px;
  text-align: center;
  & > b {
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.grayBlueMedium};
  }
`

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: calc(${props => props.theme.sizes.edge} * 1.5 - 8px);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1.5 - 8px);
  padding-top: 0;
`

const Action = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 150ms;

  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }

  & > svg {
    font-size: 11pt;
    margin-left: 8px;
    color: ${props => props.theme.colors.dark};
  }

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }
`

function FlowCard ({ piece, title, description, action, hash, to }) {
  return (
    <Wrapper>
      <Card>
        <Top>
          <Asset src={piece} />
        </Top>
        <Middle>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Middle>
        <Footer>
          {!_.isNil(hash) && (
            <HashLink smooth to={hash}>
              <Action>
                <p>{action}</p>
                <IconArrowSub />
              </Action>
            </HashLink>
          )}
          {!_.isNil(to) && (
            <ExternalLink to={to}>
              <Action>
                <p>{action}</p>
                <IconArrowForward />
              </Action>
            </ExternalLink>
          )}
        </Footer>
      </Card>
    </Wrapper>
  )
}

FlowCard.propTypes = {
  piece: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  action: PropTypes.string.isRequired,
  hash: PropTypes.string,
  to: PropTypes.string
}

FlowCard.defaultProps = {
  hash: null,
  to: null
}

export default FlowCard
