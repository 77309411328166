import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WrapperPartial = styled.div`
  grid-column: span 1;
`

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  background-color: transparent;
  transition: background-color 150ms;
  padding: calc(${props => props.theme.sizes.edge} * 3)
    calc(${props => props.theme.sizes.edge} * 1.5);
`

const Asset = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: ${props => props.background};

  & > svg {
    font-size: 18pt;
    color: ${props => props.color};
  }
`

const Title = styled.p`
  margin: calc(${props => props.theme.sizes.edge} * 1.5) 0;
  font-size: 18pt;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.white};
`

const Description = styled.div`
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin: 0;
`

const Wrapper = styled(WrapperPartial)`
  &:first-child {
    & > ${Container} {
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 250ms;
    }
  }

  &:hover,
  &:active {
    & ~ * {
      & > ${Container}, &:first-child > ${Container} {
        background-color: transparent;
        transition: background-color 250ms;
      }
    }
    & > ${Container} {
      background-color: rgba(0, 0, 0, 0.2) !important;
      transition: background-color 250ms;
    }
  }

  ${props => props.theme.medias.small} {
    & > ${Container} {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }
`

function Advantage ({ Icon, title, color, background, description }) {
  return (
    <Wrapper>
      <Container>
        <Asset color={color} background={background}>
          <Icon />
        </Asset>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Container>
    </Wrapper>
  )
}

Advantage.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]).isRequired
}

export default Advantage
