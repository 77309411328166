import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Canvas } from '../../../atoms'
import { Title } from '../../../shared/Section'
import Underlay from './Underlay'
import Advantage from './Advantage'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 3);
  background-color: ${props => props.theme.colors.dark};
  ${props => props.theme.medias.small} {
    padding-top: 0;
    margin-top: (${props => props.theme.sizes.edge} * 1);
  }
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 100;
  padding-bottom: 60px;
  margin-top: -2px;
  ${props => props.theme.medias.small} {
    flex-direction: column;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: calc(${props => props.theme.sizes.edge} * 0);
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  width: 100%;
  &:hover,
  &:active {
    & > div:first-child > div {
      background-color: transparent;
    }
  }

  ${props => props.theme.medias.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  }
  ${props => props.theme.medias.small} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  }
`

function Why ({ data }) {
  return (
    <Wrapper>
      <Content>
        <Title align='center' isLight>
          {_.get(data, 'title')}
        </Title>
        <Grid>
          {_.get(data, 'list').map(value => (
            <Advantage key={value.title} {...value} />
          ))}
        </Grid>
      </Content>
      <Underlay />
    </Wrapper>
  )
}

Why.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Why
