import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.sizes.edge};
  margin: 0 auto;
  max-width: calc(${(props) => props.theme.sizes.canvasMaxWidth});
  width: 100%;
`

function Canvas ({ className, children }) {
  return <Wrapper className={className}>{children}</Wrapper>
}

export default Canvas
