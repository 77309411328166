import React from 'react'
import styled from 'styled-components'
import { Canvas, Layer } from '../../../../atoms'
import { ReactComponent as Dots } from '../../../../../assets/decorator/dots.svg'
import { ReactComponent as Decorator } from '../../../../../assets/decorator/oval_large.svg'

const Content = styled(Canvas)`
  display: flex;
  z-index: 100;
  height: 100%;
  ${props => props.theme.medias.small} {
    flex-direction: column;
  }
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`

const Card = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: calc(
    ${props => props.theme.sizes.canvasMaxWidth} + 6 *
      ${props => props.theme.sizes.edge} + 0px
  );
  background-color: ${props => props.theme.colors.white};
  border-bottom-left-radius: 20px;
  box-shadow: -10px 12px 48px -15px rgba(0, 0, 0, 0.08);
  margin-top: -${props => props.theme.sizes.edge};
`

const Shape = styled.div`
  height: 100%;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 500px;
  background: ${props => props.theme.gradients.primaryBuilder(90)};
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const DotsWrapper = styled.div`
  height: 490px;
  width: 490px;
  margin-top: -450px;
  margin-left: calc(${props => props.theme.sizes.edge} * -1.5);
  opacity: 0;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.dark};
  }
  ${props => props.theme.medias.small} {
    opacity: 1;
  }
`

const DecoratorWrapper = styled.div`
  height: 220px;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: none;
  position: relative;

  & > svg {
    height: 100%;
    width: 100%;
    margin-right: -240px;
    margin-bottom: -200px;
    transform: rotate(30deg);
    * {
      stroke-width: 10;
    }
  }

  ${props => props.theme.medias.small} {
    display: none;
  }
`

function Underlay () {
  return (
    <>
      <Layer elevation={3}>
        <Cell />
        <Cell>
          <Shape />
        </Cell>
      </Layer>
      <Layer elevation={2}>
        <Content>
          <Cell>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </Cell>
          <Cell>
            <DecoratorWrapper>
              <Decorator />
            </DecoratorWrapper>
          </Cell>
        </Content>
      </Layer>
      <Layer elevation={1}>
        <Card />
      </Layer>
    </>
  )
}

export default Underlay
