import React from 'react'

export default {
  title: (
    <>
      <b>Smart</b> Collateral
    </>
  ),
  description: (
    <>
      Composability is DeFi's most exciting built-in feature. Pods leverages
      Aave aTokens to increase capital efficiency while locking collateral and
      minting options. Explore:
      <br />
      <br />
      <ol>
        <li>
          <b>Selling options:</b> lock aTokens (e.g. aDAI) as collateral, mint
          option tokens and sell them for a premium on the Pods AMM.
          <br />
          Earn: premium + aTokens interest.
        </li>
        <br />
        <li>
          <b>Minting and providing liquidity:</b> lock aTokens as collateral,
          mint option tokens and provide them as liquidity to the Options AMM.
          This works as if you were selling the options over time, at different
          prices.
          <br />
          Earn: premium + aTokens interest + AMM fees.
          <br />
        </li>
      </ol>
    </>
  ),
  action: 'Use smart collateral'
}
