import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IconArrow from '@material-ui/icons/ArrowBackRounded'

const Wrapper = styled(Link)`
  height: calc(${props => props.theme.sizes.navHeight} - 2 * ${props => props.theme.sizes.navVerticalEdge} - 8px);
  width: calc(${props => props.theme.sizes.navHeight} - 2 * ${props => props.theme.sizes.navVerticalEdge} - 8px);
  position: relative;
  cursor: pointer;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grayBlueLight};
  border-radius: 50%;
  transform: translateX(0);
  transition: transform 200ms, background-color 200ms;
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 20pt;
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueGhost};
    transition: transform 200ms, background-color 200ms;
  }
`

function Back ({ to }) {
  const backTo = useMemo(() => (_.isNil(to) ? '/' : to), [to])

  return (
    <Wrapper to={backTo}>
      <Container>
        <IconArrow />
      </Container>
    </Wrapper>
  )
}

Back.propTypes = {
  to: PropTypes.string
}
Back.defaultProps = {
  to: null
}

export default Back
