import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '@pods-finance/components'
import { Canvas } from '../../../atoms'
import _ from 'lodash'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 2) 0;
  background-color: ${props => props.theme.colors.platformLight};
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
  padding-bottom: 80px;
  padding-top: 80px;

  ${props => props.theme.medias.small} {
    padding-top: 0px;
  }

  ${props => props.theme.medias.micro} {
    *[data-section-content='title'] {
      max-width: 300px;
    }
  }
`

const Spacer = styled.div`
  width: 100%;
  height: calc(${props => props.theme.sizes.edge} * 1.5);
`

const HeaderLabel = styled.p`
  font-size: 12pt;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 10px 0;
`

const HeaderTitle = styled.p`
  font-size: 18pt;
  font-weight: 900;
  text-align: center;
  color: ${props => props.theme.colors.dark};
  margin: 0;
`

const Grid = styled.div`
  width: 100%;
  max-width: 700px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: calc(${props => props.theme.sizes.edge} * 3);
  ${props => props.theme.medias.small} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Item = styled.div`
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-height: 120px;
    object-fit: contain;
    filter: grayscale(100%);
    cursor: pointer;
    transition: filter 200ms;
    &:hover,
    &:active {
      filter: grayscale(0%);
      transition: filter 200ms;
    }
  }
`

export default function Investors ({ data }) {
  return (
    <Wrapper>
      <Content>
        <HeaderLabel>Our Investors</HeaderLabel>
        <HeaderTitle>Backed by the best</HeaderTitle>
        <Spacer />
        <Spacer />
        <Spacer />
        <Grid>
          {data.list.map(item => (
            <Item key={_.get(item, 'name')}>
              <ExternalLink to={_.get(item, 'website')}>
                <img
                  src={_.get(item, 'image')}
                  title={_.get(item, 'name')}
                  alt={_.get(item, 'name')}
                />
              </ExternalLink>
            </Item>
          ))}
        </Grid>
      </Content>
    </Wrapper>
  )
}
