import _ from 'lodash'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Canvas } from '../../../atoms'
import { Title, Description } from '../../../shared/Section'
import IconBuyer from '@material-ui/icons/RedoRounded'
import IconSeller from '@material-ui/icons/UndoRounded'

import Slider from './Slider'
import Underlay from './Underlay'
import Overlay from './Overlay'

const POSITION_ABSOLUTE_SELLER = 0.03
const POSITION_ABSOLUTE_BUYER = 0.97

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 2) 0;
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
  padding-bottom: 120px;

  ${props => props.theme.medias.small} {
    padding-top: 0px;
  }

  ${props => props.theme.medias.micro} {
    *[data-section-content='title'] {
      max-width: 300px;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 2);
`

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 3) 0;
  ${props => props.theme.medias.small} {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0;
    overflow: hidden;
    background-color: ${props => props.theme.colors.white};
    z-index: 500;
    top: calc(${props => props.theme.sizes.navHeight} + 10px);
    position: sticky;
  }
`

const HeaderStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.sizes.edge};
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const HeaderStatusLabel = styled.p`
  font-size: 12pt;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 10px 0;
`

const HeaderStatusTitle = styled.p`
  font-size: 18pt;
  font-weight: 900;
  text-align: center;
  color: ${props => props.theme.colors.dark};
  margin: 0;
`

const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.theme.sizes.edge};
  border-radius: 40px;

  border: 1px solid ${props => props.theme.colors.grayBlueLight};
  transition: background-color 150ms;
  user-select: none;
  cursor: pointer;

  &[data-active='true'] {
    background-color: ${props => props.theme.colors.grayBlueLight};
    border: 1px solid ${props => props.theme.colors.grayBlueNormal};
    cursor: default;
  }

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }

  & > p {
    font-size: 12pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }

  & > svg {
    font-size: 14pt;
    color: ${props => props.theme.colors.dark};
  }
  &:first-child {
    & > svg {
      margin-left: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
  }
  &:last-child {
    & > svg {
      margin-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
  }

  ${props => props.theme.medias.small} {
    border: none !important;
    flex: 1;
    justify-content: center;
    position: relative;
    background: ${props => props.theme.colors.transparent};
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background: ${props => props.theme.gradients.primary};
      transform: translate(-10px);
      opacity: 0;
      transition: opacity 200ms transform 400ms;
    }
    &[data-active='true'] {
      background-color: transparent;
      &:after {
        opacity: 1;
        transform: translate(0px);
        transition: opacity 200ms transform 400ms;
      }
    }
    &:hover,
    &:active {
      background-color: transparent;
      transition: background-color 150ms;
    }
  }
`

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`

const Spacer = styled.div`
  width: 100%;
  height: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Divider = styled.div`
  width: 100%;
  max-width: 400px;
  height: 1px;
  background-color: ${props => props.theme.colors.grayBlueLight};
  margin: 0 auto;
`

function Lifecycle ({ data }) {
  const [isSeller, setIsSeller] = useState(false)
  const [controlledPosition, setControlledPosition] = useState(
    POSITION_ABSOLUTE_BUYER
  )

  const onPositionChanged = useCallback(
    position => {
      setControlledPosition(position)
      if (position <= 0.5) setIsSeller(true)
      else if (position > 0.5) setIsSeller(false)
    },
    [setIsSeller]
  )

  return (
    <Wrapper>
      <Content>
        <Spacer />
        <Title align='center'>{_.get(data, 'title')}</Title>
        <Description align='center'>{_.get(data, 'description')}</Description>
        <Spacer />
        <Spacer />
        <Divider />
        <Container>
          <Header>
            <HeaderAction
              data-active={!isSeller}
              onClick={() => {
                setControlledPosition(POSITION_ABSOLUTE_BUYER)
                setIsSeller(false)
              }}
            >
              <p>Buyer</p>
              <IconBuyer />
            </HeaderAction>
            <HeaderStatus>
              <HeaderStatusLabel>
                {_.get(data, 'status.label')}
              </HeaderStatusLabel>
              <HeaderStatusTitle>
                {_.get(data, `status.${isSeller ? 'seller' : 'buyer'}`)}
              </HeaderStatusTitle>
            </HeaderStatus>
            <HeaderAction
              data-active={isSeller}
              onClick={() => {
                setControlledPosition(POSITION_ABSOLUTE_SELLER)
                setIsSeller(true)
              }}
            >
              <IconSeller />
              <p>Seller</p>
            </HeaderAction>
          </Header>
          <SliderWrapper>
            <Slider
              isSeller={isSeller}
              controlledPosition={controlledPosition}
              onPositionChanged={onPositionChanged}
            />
          </SliderWrapper>
        </Container>
      </Content>
      <Underlay />
      <Overlay />
    </Wrapper>
  )
}

Lifecycle.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Lifecycle
