import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Dots } from '../../../../../assets/decorator/dots.svg'
import { ReactComponent as DecoratorOvalSmall } from '../../../../../assets/decorator/oval_small.svg'
import { Layer as LayerPartial } from '../../../../atoms'

const Layer = styled(LayerPartial)`
  &[data-name='decorator'] {
    & > svg {
      height: 240px;
      width: 240px;
      position: absolute;
      top: -120px;
      right: -100px;
    }
  }
`

const DotsLeft = styled.div`
  height: 490px;
  width: 490px;
  position: absolute;
  bottom: -50px;
  left: -440px;
  opacity: 1;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.white};
  }
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const DotsRightWrapper = styled.div`
  height: 30px;
  width: 490px;
  top: 10px;
  right: -130px;
  position: absolute;
  overflow: hidden;
`

const DotsRight = styled.div`
  height: 490px;
  width: 490px;
  position: absolute;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.white};
  }
`

function Underlay () {
  return (
    <>
      <Layer elevation={4} data-name='decorator'>
        <DecoratorOvalSmall />
      </Layer>
      <Layer elevation={3}>
        <DotsLeft>
          <Dots />
        </DotsLeft>
      </Layer>
      <Layer elevation={2}>
        <DotsRightWrapper>
          <DotsRight>
            <Dots />
          </DotsRight>
        </DotsRightWrapper>
      </Layer>
    </>
  )
}

export default Underlay
