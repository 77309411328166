import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Canvas } from '../../../atoms'
import Underlay from './Underlay'
import FlowCard from './FlowCard'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Content = styled(Canvas)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  padding-bottom: 100px;
  position: relative;
`

const Title = styled.h1`
  margin: 0;
  text-align: center;
  font-size: 50pt;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.85pt;
  color: ${props => props.theme.colors.dark};
  max-width: 800px;
  ${props => props.theme.medias.medium} {
    font-size: 40pt;
    max-width: 600px;
  }
  ${props => props.theme.medias.small} {
    max-width: 400px;
    font-size: 48pt;
  }
  ${props => props.theme.medias.micro} {
    max-width: 100%;
    font-size: 36pt;
  }
`

const Description = styled.p`
  font-size: 14pt;
  font-weight: 300;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  max-width: 700px;
  text-align: center;
  margin: 0;
  & > b {
    font-weight: 600;
  }
  ${props => props.theme.medias.small} {
    max-width: 500px;
  }
`

const Spacer = styled.div`
  width: 100%;
  height: calc(
    (${props => props.theme.sizes.edge} * ${props => props.weight || 2})
  );
  ${props => props.theme.medias.small} {
    height: calc(
      (${props => props.theme.sizes.edge} * ${props => props.weightMobile || 2})
    );
  }
`

const Divider = styled.div`
  width: 160px;
  height: 8px;
  background: ${props => props.theme.gradients.primary};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;
  ${props => props.theme.medias.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: calc(${props => props.theme.sizes.edge} * 4);
  }
  ${props => props.theme.medias.small} {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: calc(${props => props.theme.sizes.edge} * 4);
  }
`

function Header ({ data }) {
  return (
    <Wrapper>
      <Content>
        <Spacer weight={5} weightMobile={3} />
        <Title>{_.get(data, 'title')}</Title>
        <Spacer weight={3} weightMobile={3} />
        <Divider />
        <Spacer weight={3} weightMobile={3} />
        <Description>{_.get(data, 'description')}</Description>
        <Spacer weight={8} weightMobile={5} />
        <Grid>
          {_.toArray(_.get(data, 'list')).map(item => (
            <FlowCard key={_.get(item, 'title')} {...item} />
          ))}
        </Grid>
      </Content>
      <Underlay />
    </Wrapper>
  )
}

export default Header
