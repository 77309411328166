import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  &[data-align='center'] {
    justify-content: center;
    text-align: center;
  }
  &[data-align='right'] {
    justify-content: flex-end;
    text-align: right;
  }
  &[data-light='true'] {
    & > p {
      color: ${props => props.theme.colors.white};
    }
  }
  ${props => props.theme.medias.small} {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
    justify-content: flex-start;
    text-align: left;

    &[data-align-small='center'] {
      justify-content: center;
      text-align: center;
    }
    &[data-align-small='right'] {
      justify-content: flex-end;
      text-align: right;
    }
  }
`

const Value = styled.p`
  font-size: 24pt;
  font-weight: 800;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  & > b,
  & > span {
    position: relative;
    font-weight: 800;
    &:after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      height: 6px;
      width: 96%;
      margin-left: 2%;
      background: ${props => props.theme.gradients.primary};
    }
  }

  ${props => props.theme.medias.small} {
    font-size: 26pt;
    & > b,
    & > span {
      &:after {
        bottom: -8px;
        height: 7px;
      }
    }
  }
`

function Title ({ className, align, alignSmall, isLight, children }) {
  return (
    <Wrapper
      className={className}
      data-align={align}
      data-align-small={alignSmall}
      data-light={isLight}
    >
      <Value data-section-content='title'>{children}</Value>
    </Wrapper>
  )
}

Title.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  alignSmall: PropTypes.oneOf(['left', 'center', 'right']),
  isLight: PropTypes.bool
}

Title.defaultProps = {
  align: 'left',
  alignSmall: 'left',
  isLight: false
}

export default Title
