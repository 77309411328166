import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ExternalLink } from '@pods-finance/components'

import IconArrow from '@material-ui/icons/ArrowForwardRounded'

import IconNews from '@material-ui/icons/FlashOnRounded'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  width: 95%;
  max-width: 500px;
  overflow: hidden;
  height: 30px;
  ${props => props.theme.medias.small} {
    max-width: 100%;
    width: 100%;
    height: 40px;
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 2);
  background-color: ${props => props.theme.colors.dark};
  & > p {
    font-size: 9pt;
    font-weight: 700;
    margin: 0;
    color: ${props => props.theme.colors.white};
    display: inherit;
  }

  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.white};
    display: none;
    transform: rotate(15deg);
  }

  ${props => props.theme.medias.small} {
    padding: 0 calc(${props => props.theme.sizes.edge} * 0.8);
    & > svg {
      display: inherit;
    }
    & > p {
      display: none;
    }
  }
`

const ContentPartial = styled(ExternalLink)`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  padding-left: ${props => props.theme.sizes.edge};
  background-color: ${props => props.theme.colors.grayBlueGhost};
  transition: background-color 150ms;
  cursor: pointer;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  & > p {
    margin: 0;
    font-size: 9pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    width: 100%;
    max-width: 400px;
    ${props => props.theme.extensions.elllipsis};
    padding: 5px 0;

    ${props => props.theme.medias.small} {
      font-weight: 600;
      font-size: 10pt;
    }
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  transition: background-color 150ms;
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 14pt;
  }
`

const Content = styled(ContentPartial)`
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }
`

function News ({ title, to }) {
  return (
    <Wrapper>
      <Label>
        <IconNews />
        <p>News</p>
      </Label>
      <Content to={to}>
        <Title>
          <p>{_.toString(title)}</p>
        </Title>
        <Action>
          <IconArrow />
        </Action>
      </Content>
    </Wrapper>
  )
}

News.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string
}

News.defaultProps = {
  title: 'Pods news',
  to: null
}

export default News
