import _ from 'lodash'
import styled from 'styled-components'

const Layer = styled.div`
  display: flex;
  position: absolute;
  z-index: ${(props) =>
    _.attempt(() => parseInt(props.elevation, 10) || 1) * 10};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export default Layer
