import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import globals from '@pods-finance/globals'
import { Button } from '@pods-finance/components'
import { Canvas } from '../../atoms'
import { Title, Description } from '../Section'

import { ReactComponent as Curly } from '../../../assets/decorator/curly.svg'

import Shape1 from '../../../assets/images/shape_1.png'
import Shape2 from '../../../assets/images/shape_2.png'
import Shape3 from '../../../assets/images/shape_3.png'
import Shape5 from '../../../assets/images/shape_5.png'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 6);
  padding-top: calc(${props => props.theme.sizes.edge} * 4);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  & > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    grid-column: span 1;
    min-height: 500px;
    padding: calc(${props => props.theme.sizes.edge} * 8)
      calc(${props => props.theme.sizes.edge} * 2);
  }
  ${props => props.theme.medias.small} {
    grid-template-columns: 1fr;
    & > * {
      width: 100%;
      min-height: auto;
      padding: calc(${props => props.theme.sizes.edge} * 4)
        ${props => props.theme.sizes.edge};
    }
  }
`

const Left = styled.div`
  background: ${props => props.theme.gradients.primary};
`

const Right = styled.div`
  background: ${props => props.theme.colors.dark};
`

const Decorator = styled.div`
  width: 100%;
  position: relative;
  margin-top: -4px;
  & > svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 1440px;
    position: absolute;
    transform-origin: 50% 0;
    min-height: 300px;
  }
  ${props => props.theme.medias.small} {
    height: 100px;
    & > svg {
      width: 100%;
    }
  }
`

const ReminderTitle = styled.p`
  font-size: 20pt;
  font-weight: 800;
  color: ${props => props.theme.colors.white};
  line-height: 1.5;
  max-width: 400px;
  text-align: center;
  margin: 0;
`

const ReminderDescription = styled.p`
  font-size: 11pt;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  line-height: 1.5;
  margin: 0;
  max-width: 500px;
  text-align: center;
  min-height: 80px;
`

const ReminderSpacer = styled.div`
  width: 100%;
  height: calc(${props => props.theme.sizes.edge} * 4);
`

const Shapes = styled(Canvas)`
  width: 100%;
  position: relative;
  height: 70px;
  z-index: 80;
  margin: 0 auto;
`

const ShapeWrapper = styled.div`
  position: absolute;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &[data-id='yellow-1'] {
    height: 160px;
    width: 160px;
    left: 340px;
    top: -40px;
    transform: rotate(60deg);
    filter: drop-shadow(0px 0px 40px #fdd15380);
  }
  &[data-id='red-1'] {
    height: 160px;
    width: 160px;
    left: -20px;
    top: -80px;
    transform: rotate(-60deg);
    filter: drop-shadow(0px 0px 30px #d4007c70);
  }
  &[data-id='blue-1'] {
    height: 90px;
    width: 90px;
    right: 200px;
    top: 10px;
    transform: rotate(0deg);
    filter: drop-shadow(0px 0px 20px #3fb1e550);
  }
  &[data-id='purple-1'] {
    height: 100px;
    width: 100px;
    left: 40px;
    top: 180px;
    transform: rotate(0deg);
    filter: drop-shadow(0px 0px 15px #d326ff40);
  }
  &[data-id='purple-2'] {
    height: 180px;
    width: 180px;
    right: -50px;
    top: 100px;
    transform: rotate(0deg);
    filter: drop-shadow(0px 0px 15px #d326ff40);
  }

  ${props => props.theme.medias.small} {
    &[data-id='yellow-1'],
    &[data-id='blue-1'],
    &[data-id='purple-1'] {
      display: none;
    }

    &[data-id='purple-2'] {
      top: 60px;
    }
  }
`

function Reminder ({ data, withDecorator, withShapes }) {
  return (
    <Wrapper>
      {withDecorator && (
        <Decorator>
          <Curly />
        </Decorator>
      )}
      {withShapes && (
        <Shapes>
          <ShapeWrapper data-id='yellow-1'>
            <img alt='' src={Shape1} />
          </ShapeWrapper>
          <ShapeWrapper data-id='red-1'>
            <img alt='' src={Shape2} />
          </ShapeWrapper>
          <ShapeWrapper data-id='blue-1'>
            <img alt='' src={Shape3} />
          </ShapeWrapper>
          <ShapeWrapper data-id='purple-1'>
            <img alt='' src={Shape5} />
          </ShapeWrapper>
          <ShapeWrapper data-id='purple-2'>
            <img alt='' src={Shape5} />
          </ShapeWrapper>
        </Shapes>
      )}
      <Content>
        <Title align='center'>{_.get(data, 'title')}</Title>
        <Description align='center'>{_.get(data, 'description')}</Description>
      </Content>
      <Grid>
        <Left>
          <ReminderTitle>{_.get(data, 'sell.title')}</ReminderTitle>
          <ReminderSpacer />
          <ReminderDescription>
            {_.get(data, 'sell.description')}
          </ReminderDescription>
          <ReminderSpacer />
          <Button
            type={t => t.external}
            appearance={a => a.gradient}
            accent={a => a.whitePrimary}
            to={globals.host.platform.auto()}
            title='Sell put options'
          />
        </Left>
        <Right>
          <ReminderTitle>{_.get(data, 'buy.title')}</ReminderTitle>
          <ReminderSpacer />
          <ReminderDescription>
            {_.get(data, 'buy.description')}
          </ReminderDescription>
          <ReminderSpacer />
          <Button
            type={t => t.external}
            appearance={a => a.solid}
            accent={a => a.whiteDark}
            to={globals.host.platform.auto()}
            title='Buy put options'
          />
        </Right>
      </Grid>
    </Wrapper>
  )
}

Reminder.propTypes = {
  data: PropTypes.shape({}).isRequired,
  withDecorator: PropTypes.bool,
  withShapes: PropTypes.bool
}

Reminder.defaultProps = {
  withDecorator: true,
  withShapes: false
}

export default Reminder
