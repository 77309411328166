import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  &[data-align='center'] {
    justify-content: center;
    text-align: center;
  }
  &[data-align='right'] {
    justify-content: flex-end;
    text-align: right;
  }
  &[data-light='true'] {
    & > * {
      color: ${props => props.theme.colors.white};
    }
  }

  ${props => props.theme.medias.small} {
    justify-content: flex-start;
    text-align: left;

    justify-content: flex-start;
    text-align: left;

    &[data-align-small='center'] {
      justify-content: center;
      text-align: center;
    }
    &[data-align-small='right'] {
      justify-content: flex-end;
      text-align: right;
    }
  }
`

const Value = styled.div`
  font-size: 12pt;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  margin: 0;
  max-width: 600px;
  & > b {
    font-weight: 600;
  }
`

function Description ({ className, align, alignSmall, isLight, children }) {
  return (
    <Wrapper
      data-align={align}
      data-align-small={alignSmall}
      data-light={isLight}
      className={className}
    >
      <Value data-section-content='description'>{children}</Value>
    </Wrapper>
  )
}

Description.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  alignSmall: PropTypes.oneOf(['left', 'center', 'right']),
  isLight: PropTypes.bool,
  className: PropTypes.string
}

Description.defaultProps = {
  align: 'left',
  alignSmall: 'left',
  isLight: false,
  className: null
}

export default Description
