import React from 'react'
import globals from '@pods-finance/globals'

export default {
  title: (
    <>
      Buidl on <b>Ethereum</b>
    </>
  ),
  cell: {
    left: {
      title: 'Buidl with us',
      list: [
        {
          to: globals.links.github.primary.link,
          title: 'Github'
        },
        {
          to: globals.links.gitbook.primary.link,
          title: 'Docs'
        }
      ]
    },
    right: {
      title: 'Join the community',
      list: [
        {
          to: globals.links.twitter.primary.link,
          title: 'Twitter'
        },
        {
          to: globals.links.discord.primary.link,
          title: 'Discord'
        },
        {
          to: globals.links.medium.primary.link,
          title: 'Medium'
        },
        {
          to: globals.links.notion.hire.link,
          title: 'Join our team'
        }
      ]
    }
  }
}
