import _ from 'lodash'
import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconReload from '@material-ui/icons/RefreshRounded'
import { Canvas } from '../../../atoms'
import { Title, Description } from '../../../shared/Section'

import Strategy from './Strategy'
import Underlay from './Underlay'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 3) 0;
`

const Content = styled(Canvas)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 100;
`

const Strategies = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 5);
  ${props => props.theme.medias.medium} {
    order: 3;
  }
  ${props => props.theme.medias.small} {
    padding-top: calc(${props => props.theme.sizes.edge} * 2);
    grid-template-columns: repeat(1, 1fr);
    grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
  }
`

const RefreshIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.grayBlueGhost};
  transition: background-color 150ms;
  & > svg {
    font-size: 14pt;
    color: ${props => props.theme.colors.grayBlueDark};
    transition: color 150ms;
  }
`

const Refresh = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 125px;
  right: 0;
  cursor: pointer;
  & > p {
    font-size: 12pt;
    font-weight: 500;
    color: ${props => props.theme.colors.grayBlueMedium};
    margin: 0 12px 0 0;
    transition: color 150ms;
  }
  &:hover,
  &:active {
    ${RefreshIcon} {
      background-color: ${props => props.theme.colors.grayBlueLight};
      transition: background-color 150ms;
      & > svg {
        color: ${props => props.theme.colors.grayBlueDark};
        transition: color 150ms;
      }
    }
    & > p {
      color: ${props => props.theme.colors.grayBlueDark};
      transition: color 150ms;
    }
  }

  ${props => props.theme.medias.medium} {
    position: relative;
    order: 2;
    top: 0;
    justify-content: flex-start;
    margin-top: calc(${props => props.theme.sizes.edge} * 2);
    & > p {
      font-size: 11pt;
    }
  }
  ${props => props.theme.medias.medium} {
    justify-content: center;
  }
`

function Knowledge ({ data }) {
  const [index, setIndex] = useState(0)

  const strategies = useMemo(() => _.get(data, 'list'), [data])

  const chosen = useMemo(
    () =>
      strategies
        .slice(index, Math.min(index + 3, strategies.length))
        .map((element, id) => ({ ...element, id })),
    [strategies, index]
  )

  const increment = useCallback(() => {
    if (index + 3 >= strategies.length) setIndex(0)
    else setIndex(prev => prev + 3)
  }, [index, setIndex, strategies])

  return (
    <Wrapper>
      <Content>
        <Title alignSmall='center'>{_.get(data, 'title')}</Title>
        <Description alignSmall='center'>
          {_.get(data, 'description')}
        </Description>
        <Strategies>
          {chosen.map(item => (
            <Strategy key={`${item.title}-${item.id}`} {...item} />
          ))}
        </Strategies>
        <Refresh onClick={() => increment()}>
          <p>{_.get(data, 'more')}</p>
          <RefreshIcon>
            <IconReload />
          </RefreshIcon>
        </Refresh>
      </Content>
      <Underlay />
    </Wrapper>
  )
}

Knowledge.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Knowledge
