import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoAsset from '../../../../assets/logo/pods_white.svg'

const LogoWrapper = styled(Link)`
  height: 30px;
  cursor: pointer;
  & > img {
    object-fit: contain;
    object-position: left;
    height: 100%;
    min-width: 62px;
  }
`

function Logo ({ asset }) {
  return (
    <LogoWrapper to='/'>
      <img src={_.isNil(asset) ? LogoAsset : asset} alt='' />
    </LogoWrapper>
  )
}

Logo.propTypes = {
  asset: PropTypes.number
}

Logo.defaultProps = {
  isWhite: null
}

export default Logo
