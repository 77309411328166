import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'

import IconExpand from '@material-ui/icons/KeyboardArrowDownRounded'
import IconVisit from '@material-ui/icons/ArrowForwardRounded'

const WrapperPartial = styled.div`
  grid-column: span 1;
  &:first-child {
    padding-top: calc(${props => props.theme.sizes.edge} * 1);
  }
  &:nth-child(2) {
    padding-top: calc(${props => props.theme.sizes.edge} * 4);
  }

  ${props => props.theme.medias.small} {
    &:first-child,
    &:nth-child(2) {
      padding-top: 0;
    }
  }
`

const Card = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
`

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1)
    calc(${props => props.theme.sizes.edge} * 1.5);
  z-index: 100;

  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
  }
`

const Label = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  border: 2px solid ${props => props.theme.colors.dark};
  padding: calc(${props => props.theme.sizes.edge} * 0.5)
    calc(${props => props.theme.sizes.edge} * 1);
  text-align: center;
  margin-right: auto;
  & > p {
    font-size: 9pt;
    font-weight: 800;
    margin: 0;
    color: ${props => props.theme.colors.dark};
  }
  ${props => props.theme.medias.small} {
    border-width: 1px;
  }
`

const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.grayBlueGhost};

  transition: background-color 150ms;
  cursor: pointer;
  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.grayBlueDark};
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }
`

const Content = styled.div`
  position: relative;
  padding: calc(${props => props.theme.sizes.edge} * 1)
    calc(${props => props.theme.sizes.edge} * 1.5);
  padding-top: 0;
  z-index: 100;
  min-height: 100px;

  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
    padding-top: 0;
  }
`

const Underlay = styled.div`
  width: 100%;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  &:after {
    content: '';
    display: block;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-left: -100px;
    margin-top: -180px;
    opacity: 0.7;
    background: ${props => props.theme.colors.grayBlueGhost};
  }
`

const Title = styled.p`
  font-size: 15pt;
  font-weight: 900;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  margin: 0;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  ${props => props.theme.medias.small} {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  }
`

const Description = styled.div`
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-image: ${props =>
      props.theme.gradients.transparentBuilder('#ffffff', 0)};
  }
  & > p {
    position: relative;
    z-index: 50;
    font-size: 11pt;
    font-weight: 400;
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
    margin: 0;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 60px;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 0.5);
  ${props => props.theme.medias.small} {
    height: 50px;
  }
`

const FooterAction = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top-left-radius: 32px;
  background-color: ${props => props.theme.colors.grayBlueGhost};
  transition: background-color 150ms;
  padding: 0 24px 0 32px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }

  & > p {
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }

  & > svg {
    margin-left: ${props => props.theme.sizes.edge};
    font-size: 13pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Wrapper = styled(WrapperPartial)`
  ${HeaderAction} {
    transform: rotate(0deg);
    transition: transform 250ms;
  }
  ${Description} {
    max-height: 50px;
    transition: max-height 250ms;
    &:after {
      opacity: 1;
      transition: opacity 250ms;
    }
  }

  &[data-expanded='true'] {
    ${HeaderAction} {
      transform: rotate(180deg);
      transition: transform 250ms;
    }
    ${Description} {
      max-height: 800px;
      transition: max-height 250ms;
      &:after {
        opacity: 0;
        transition: opacity 200ms 100ms;
      }
    }
  }

  &[data-dark='true'] {
    ${Card} {
      background-color: ${props => props.theme.colors.dark};
    }
    ${Title} {
      color: ${props => props.theme.colors.white};
    }
    ${Description} {
      & > p {
        color: ${props => props.theme.colors.white};
      }
      &:after {
        background-image: ${props =>
          props.theme.gradients.transparentBuilder(props.theme.colors.dark, 0)};
      }
    }

    ${Label} {
      border-color: ${props => props.theme.colors.white};
      & > p {
        color: ${props => props.theme.colors.white};
      }
    }
    ${Underlay}:after {
      background-color: rgba(255, 255, 255, 0.1);
    }
    ${FooterAction} {
      background-color: rgba(255, 255, 255, 0.1);
      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 0.3);
      }
      & > p,
      & > svg {
        color: ${props => props.theme.colors.white};
      }
    }
    ${HeaderAction} {
      background-color: rgba(255, 255, 255, 0.1);
      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 0.3);
      }
      & > svg {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

function Strategy ({ title, description, label, call, to, isDark }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Wrapper data-expanded={isExpanded} data-dark={isDark}>
      <Card>
        <Header>
          <Label>
            <p>{label}</p>
          </Label>
          <HeaderAction onClick={() => setIsExpanded(p => !p)}>
            <IconExpand />
          </HeaderAction>
        </Header>
        <Content>
          <Title>{title}</Title>
          <Description>
            <p>{description}</p>
          </Description>
        </Content>
        <Footer>
          <FooterAction to={to}>
            <p>{call}</p>
            <IconVisit />
          </FooterAction>
        </Footer>
        <Underlay />
      </Card>
    </Wrapper>
  )
}

Strategy.propTypes = {
  isDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]).isRequired,
  call: PropTypes.string,
  to: PropTypes.string
}

Strategy.defaultProps = {
  to: globals.host.platform.auto(),
  call: 'Open a position'
}

export default Strategy
