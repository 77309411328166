import _ from 'lodash'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Layer } from '../../../../atoms'
import { ReactComponent as DottedOval } from '../../../../../assets/decorator/oval_white.svg'
import { ReactComponent as DottedLine } from '../../../../../assets/decorator/line_white.svg'

import { ReactComponent as LogoAsset } from '../../../../../assets/logo/pods_white.svg'

import Shape1 from '../../../../../assets/images/shape_1.png'
import Shape2 from '../../../../../assets/images/shape_2.png'
import Shape3 from '../../../../../assets/images/shape_3.png'
import Shape4 from '../../../../../assets/images/shape_4.png'
import Shape5 from '../../../../../assets/images/shape_5.png'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  ${props => props.theme.medias.custom(1100)} {
    margin-left: 80px;
  }
  ${props => props.theme.medias.small} {
    margin-left: 0;
  }
`

const Content = styled.div`
  width: 100%;
  height: 340px;
  position: relative;
  & > ${Layer} {
    height: 100%;
    width: 100%;
  }

  ${props => props.theme.medias.small} {
    height: 500px;
    padding: calc(${props => props.theme.sizes.edge} * 2);
    max-width: 500px;
    margin: 0 auto;
  }
`

const Dash = keyframes`
   to {
    stroke-dashoffset: -1000;
  }
`

const Animated = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 1;
  & > svg {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    * {
      stroke-dasharray: 1 20;
      animation-name: ${Dash};
      animation-duration: 100s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      stroke-width: 1;
    }
    &[data-type='line'] {
      width: 70%;
      margin: auto;
      &:nth-child(1) {
        transform: rotate(0deg);
      }
      &:nth-child(2) {
        width: 50%;
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        transform: rotate(30deg);
      }
      &:nth-child(4) {
        transform: rotate(-30deg);
      }
    }
  }
`

const OverContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Logo = styled.div`
  padding: 15px 40px;
  border-radius: 100px;
  background: ${props => props.theme.gradients.primary};

  & > svg {
    height: 30px;
    width: 60px;
  }
`

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: ${props => props.size || '100'}px;
  width: ${props => props.size || '100'}px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  transform: rotate(${props => props.rotate || '0'}deg);

  ${props =>
    ['top', 'left', 'right', 'bottom'].map(orientation =>
      _.has(props, orientation)
        ? css`
            ${orientation}: ${props[orientation]}px;
          `
        : css``
    )}

  ${props => props.theme.medias.small} {
    ${props =>
      _.has(props, 'mobile-size') &&
      css`
        height: ${props['mobile-size']}px;
        width: ${props['mobile-size']}px;
      `}

    ${props =>
      ['top', 'left', 'right', 'bottom'].map(orientation =>
        _.has(props, `mobile-${orientation}`)
          ? css`
              ${orientation}: ${props[`mobile-${orientation}`]}px;
            `
          : css``
      )}
  }
`

const Shape = styled.img`
  height: 90%;
  width: 90%;
  object-fit: contain;
`

function Illustration () {
  return (
    <Wrapper>
      <Content>
        <Layer elevation={2}>
          <OverContent>
            <Card
              top={0}
              left={-10}
              rotate={-20}
              size={160}
              mobile-left={-40}
              mobile-top={70}
              mobile-size={100}
            >
              <Shape src={Shape1} />
            </Card>
            <Card top={-60} mobile-top={40} mobile-size={110}>
              {' '}
              <Shape src={Shape2} />
            </Card>
            <Card
              top={15}
              right={10}
              rotate={10}
              size={140}
              mobile-top={90}
              mobile-right={-20}
              mobile-size={90}
            >
              <Shape src={Shape3} />
            </Card>
            <Card bottom={-50} mobile-bottom={40}>
              <Shape src={Shape4} />
            </Card>
            <Card
              bottom={50}
              left={80}
              rotate={-15}
              size={60}
              mobile-left={-10}
              mobile-bottom={100}
              mobile-size={90}
            >
              {' '}
              <Shape src={Shape5} />
            </Card>
            <Card
              bottom={40}
              right={70}
              rotate={5}
              size={80}
              mobile-right={-20}
              mobile-bottom={100}
            >
              <Shape src={Shape1} />
            </Card>
            <Logo>
              <LogoAsset />
            </Logo>
          </OverContent>
        </Layer>
        <Layer elevation={1}>
          <Animated>
            <DottedOval />
            <DottedLine data-type='line' />
            <DottedLine data-type='line' />
            <DottedLine data-type='line' />
            <DottedLine data-type='line' />
          </Animated>
        </Layer>
      </Content>
    </Wrapper>
  )
}

export default Illustration
