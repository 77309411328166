import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.dark};
  height: 180px;
  width: 100%;
  z-index: 1;
`

function Underlay () {
  return <Wrapper />
}

export default Underlay
