import _ from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const LETTER_ANIMATION_DURATION = 6000
const LETTER_ANIMATION_OFFSET = 200
const LETTER_ANIMATION_DISTANCE_TOP = 200
const LETTER_ANIMATION_DISTANCE_BOTTOM = 100

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const Title = styled.h1`
  display: inline;
  margin: 0;
  font-size: 50pt;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.85pt;
  color: ${props => props.theme.colors.dark};
  max-width: 600px;
  ${props => props.theme.medias.custom(1050)} {
    font-size: 40pt;
    max-width: 100%;
  }
  ${props => props.theme.medias.small} {
    font-size: 48pt;
    text-align: center;
  }
  ${props => props.theme.medias.micro} {
    font-size: 36pt;
  }
`

const Static = styled.p`
  margin: 0;
  display: inline;
`
const Animated = styled.div`
  margin: 0 0 0 10pt;
  display: inline;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 2px;
    width: 50px;
    height: 8px;
    background: ${props => props.theme.gradients.primary};
  }
  ${props => props.theme.medias.medium} {
    margin: 0;
    display: flex;
  }
  ${props => props.theme.medias.small} {
    justify-content: center;
    &:after {
      left: auto;
    }
  }
`

const LetterAnimation = keyframes`
  0%, 1%{
    transform: translateY(calc(${LETTER_ANIMATION_DISTANCE_TOP} * -1px));
  }

  10%, 89% {
    transform: translateY(0);
  }
  99%, 100% {
    transform: translateY(calc(${LETTER_ANIMATION_DISTANCE_BOTTOM} * 1px));
  }
`

const Letter = styled.div`
  margin: 0 0 -5px 0;
  overflow: hidden;
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;

  &:after {
    content: '${props => props.letter || 'A'}';
    opacity: 0;
    position: relative;
  }

  & > div {
    position: absolute;
    left: 0;
    bottom: 0;
    vertical-align: text-bottom;
    color: ${props => props.theme.colors.middle};
    transform: translateY(calc(${LETTER_ANIMATION_DISTANCE_TOP} * -1px));
    animation-name: ${LetterAnimation};
    user-select: none;
    animation-duration: calc(
      (
          ${LETTER_ANIMATION_DURATION} - ${props => props.index} *
            ${LETTER_ANIMATION_OFFSET} + 0
        ) * 1ms
    );
    animation-delay: calc(
      (${props => props.index} * ${LETTER_ANIMATION_OFFSET}) * 1ms
    );
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: ease-in;
  }
`

function Headline ({ title, assets }) {
  const [index, setIndex] = useState(0)
  const asset = useMemo(() => assets[index], [assets, index])

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(previous => (previous === assets.length - 1 ? 0 : previous + 1))
    }, LETTER_ANIMATION_DURATION)
    return () => clearInterval(interval)
  }, [assets])

  return (
    <Wrapper>
      <Title>
        <Static>{_.toString(title)}</Static>
        <Animated>
          {_.toString(asset)
            .split('')
            .map((letter, index) => (
              <Letter key={`${letter}-${asset}`} index={index} letter={letter}>
                <div>{letter}</div>
              </Letter>
            ))}
        </Animated>
      </Title>
    </Wrapper>
  )
}

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(PropTypes.string)
}

Headline.defaultProps = {
  assets: []
}

export default Headline
