import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import globals from '@pods-finance/globals'
import { Button } from '@pods-finance/components'
import { Canvas } from '../../../atoms'
import Underlay from './Underlay'
import News from './News'
import Headline from './Headline'

import AssetPlatform from '../../../../assets/images/header_asset_4.png'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 580px;

  ${props => props.theme.medias.custom(1050)} {
    height: auto;
  }
`

const Content = styled(Canvas)`
  display: flex;
  z-index: 100;
  height: 100%;
  ${props => props.theme.medias.small} {
    flex-direction: column;
  }
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`

const Asset = styled.img`
  max-width: 600px;
  z-index: 100;
  padding-left: 80px;
  margin-top: 20px;
  object-fit: contain;
  object-position: top;

  ${props => props.theme.medias.medium} {
    margin-top: 0;
    max-width: 540px;
  }
`

const Layer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  z-index: 100;
  ${Cell} {
    padding: calc(${props => props.theme.sizes.edge} * 3) 0 0 0;
  }

  ${props => props.theme.medias.custom(1050)} {
    ${Cell} {
      &[data-side='left'] {
        padding-bottom: 30px;
      }
    }
  }

  ${props => props.theme.medias.small} {
    ${Cell} {
      &[data-side='right'] {
        display: none;
      }
      &[data-side='left'] {
        align-items: center;
      }
    }
  }
`

const Description = styled.p`
  font-size: 14pt;
  font-weight: 300;
  color: ${props => props.theme.colors.dark};
  line-height: 1.5;
  max-width: 460px;
  margin: 0;
  & > b {
    font-weight: 600;
  }
  ${props => props.theme.medias.small} {
    text-align: center;
  }
`

const Spacer = styled.div`
  width: 100%;
  height: calc(
    (${props => props.theme.sizes.edge} * ${props => props.weight || 2})
  );
  ${props => props.theme.medias.small} {
    &[data-under='news'] {
      height: calc(${props => props.theme.sizes.edge} * 2);
    }
  }
`

function Header ({ data }) {
  return (
    <Wrapper>
      <Layer elevation={4}>
        <Content data-random={globals._RANDOM_BUILD_INDEX}>
          <Cell data-side='left'>
            {!_.isNil(_.get(data, 'news.title')) && (
              <News {..._.get(data, 'news')} />
            )}
            <Spacer weight={2} data-under='news' />
            <Headline
              title={_.get(data, 'headline.title')}
              assets={_.get(data, 'headline.assets')}
            />
            <Spacer weight={2} />
            <Description>{_.get(data, 'description')}</Description>
            <Spacer weight={2} />
            <Button
              type={t => t.external}
              appearance={a => a.gradient}
              accent={a => a.primary}
              title={_.get(data, 'action')}
              to={globals.host.platform.auto('/buy')}
            />
            <Spacer />
          </Cell>
          <Cell data-side='right'>
            <Asset src={AssetPlatform} />
          </Cell>
        </Content>
      </Layer>
      <Underlay />
    </Wrapper>
  )
}

Header.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.shape({}).isRequired
  }).isRequired
}

export default Header
