import React from 'react'
import PropTypes from 'prop-types'
import ReactCompareImage from 'react-compare-image'
import styled, { keyframes } from 'styled-components'
import IllustrationSeller from '../../../../../assets/images/seller_right_put_2x.png'
import IllustrationBuyer from '../../../../../assets/images/buyer_left_put_2x.png'

import IllustrationSellerPortrait from '../../../../../assets/images/seller_portrait_put_2x.png'
import IllustrationBuyerPortrait from '../../../../../assets/images/buyer_portrait_put_2x.png'

import IconSlider from '@material-ui/icons/UnfoldMoreRounded'

const WrapperPartial = styled.div`
  width: 100%;
  height: auto;
  background-color: white;

  border-radius: 6px;
  *[data-testid='container'] > div > div {
    &:first-child,
    &:last-child {
      background-color: ${props => props.theme.colors.grayBlueDark} !important;
      box-shadow: none !important;
      width: 1px !important;
    }
  }
  user-select: none;
  box-shadow: 0 4px 45px -5px rgba(0, 0, 0, 0.15);

  ${props => props.theme.medias.small} {
    background-color: transparent;
    height: auto;
    min-height: 500px;
    box-shadow: none;
  }
`

const Comparer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`

const HandleAnimation = keyframes`
  0%{
    opacity: 0;
    transform: scale(0);
  }

  10%{
    opacity: 1;
  }

  80%{
    opacity: 1;
  }

  100%{
    opacity: 0;
    transform: scale(2.2);
  }
`

const Handle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.dark};
  & > svg {
    position: relative;
    z-index: 2;
    font-size: 14pt;
    transform: rotate(90deg);
    color: ${props => props.theme.colors.dark};
  }

  & > div {
    position: absolute;
    z-index: 1;
    background-color: ${props => props.theme.colors.grayBlueLight};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    animation-name: ${HandleAnimation};
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }
`

const Poster = styled.div`
  width: calc(100% + 2 * ${props => props.theme.sizes.edge});
  margin-left: -${props => props.theme.sizes.edge};
  margin-top: ${props => props.theme.sizes.edge};
  position: relative;
  box-shadow: 0 4px 45px -5px rgba(0, 0, 0, 0.15);
`

const Wrapper = styled(WrapperPartial)`
  & > ${Poster} {
    display: none;
  }
  & > ${Comparer} {
    display: block;
  }
  ${props => props.theme.medias.small} {
    & > ${Poster} {
      display: block;
    }
    & > ${Comparer} {
      display: none;
    }
  }
`

const PortraitSeller = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  user-select: none;
  object-fit: contain;

  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms transform 300ms;

  &[data-active='true'] {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms transform 300ms;
  }
`

const PortraitBuyer = styled(PortraitSeller)`
  position: absolute;
  left: 0;
  top: 0;
`

function Slider ({ isSeller, controlledPosition, onPositionChanged }) {
  return (
    <Wrapper>
      <Comparer>
        <ReactCompareImage
          leftImage={IllustrationBuyer}
          rightImage={IllustrationSeller}
          handle={
            <Handle>
              <div />
              <IconSlider />
            </Handle>
          }
          sliderPositionPercentage={controlledPosition}
          onSliderPositionChange={onPositionChanged}
        />
      </Comparer>
      <Poster>
        <PortraitSeller
          data-active={isSeller}
          src={IllustrationSellerPortrait}
        />
        <PortraitBuyer
          data-active={!isSeller}
          src={IllustrationBuyerPortrait}
        />
      </Poster>
    </Wrapper>
  )
}

Slider.propTypes = {
  isSeller: PropTypes.bool.isRequired,
  onPositionChanged: PropTypes.func.isRequired,
  controlledPosition: PropTypes.number.isRequired
}

export default Slider
