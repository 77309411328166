import React, { useEffect } from 'react'
import styled from 'styled-components'

import { data } from '../../constants'

import { Nav, Footer, Reminder } from '../../components/shared'
import { Header, Collateral, Liquidity } from '../../components/specific/Seller'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

const Content = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.navHeight} - 1px);
`

function Seller () {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <Nav isStatic />
      <Content>
        <Header data={data.earn} />
        <Collateral data={data.collateral} />
        <Liquidity data={data.liquidity} />
        <Reminder data={data.reminder} withShapes withDecorator={false} />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default Seller
